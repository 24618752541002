/* eslint-disable @typescript-eslint/no-explicit-any */

import { create } from "zustand";

type User = any; 
type Profile = any;
type Grant = string;
type Org = any;
type Account = any;
interface AppState {
	user: User;
	profile: Profile;
	grant: Grant;
	org: Org;
	account: Account;
	setProfile: (profile: Profile) => Promise<void>;
	setUser: (user: User) => Promise<void>;
	setGrant: (grant: Grant) => Promise<void>;
	setOrg: (org: Org) => Promise<void>;
	setAccount: (account: Account) => Promise<void>;
}

export const useStore = create<AppState>((set) => ({
	user: {},
	profile: {},
	grant: "",
	org: {},
	account: {},
	setProfile: async (profile) => set({ profile }),
	setUser: async (user) => set({ user }),
	setGrant: async (grant) => set({ grant }),
	setOrg: async (org) => set({ org }),
	setAccount: async (account) => set({ account }),
}));
