/* eslint-disable @typescript-eslint/no-explicit-any */
export const FeatureFlags = {
	NewDashboard: "newDashboard",
	PremiumAnalytics: "premiumAnalytics",
	ExperimentalUI: "experimentalUI",
	Superuser: "superuser",
	Authenticated: "authenticated",
	Anonymous: "anonymous",
	HasProfiles: "has_profiles",
	NoProfile: "no_profile",
	IsProfileOwner: "owner",
	IsActiveProfile: "active_profile",
	IsOrgProfile: "org",
	DomainUrlEnabledForSignup: "domain_enabled_for_signup",
};

export const featureFlags: any = {
	[FeatureFlags.NewDashboard]: true, // Simple enablement (for everyone)
	[FeatureFlags.PremiumAnalytics]: false,
	[FeatureFlags.ExperimentalUI]: {
		route: "/experimental",
		roles: ["admin", "beta-tester"],
	},
	[FeatureFlags.Superuser]: {
		validations: [(user: any) => user.is_superuser],
	},
	[FeatureFlags.Authenticated]: {
		validations: [(user: any) => !!user?.uuid],
	},
	[FeatureFlags.Anonymous]: {
		validations: [(user: any) => {
			const isAnonymous = user?.username == null;
			return isAnonymous;
		}],
	},
	[FeatureFlags.HasProfiles]: {
		validations: [(user: any) => user?.profiles?.length > 1],
	},
	[FeatureFlags.NoProfile]: {
		validations: [(user: any) => user?.profiles?.length == 0],
	},
	[FeatureFlags.IsProfileOwner]: {
		validations: [(user: any) => user?.profile?.group == "owner"],
	},
	[FeatureFlags.IsActiveProfile]: {
		validations: [(user: any) => !!user?.profile?.uuid],
	},
	[FeatureFlags.IsOrgProfile]: {
		validations: [(user: any) => user?.profile?.kind == "organisation"],
	},
	[FeatureFlags.DomainUrlEnabledForSignup]: {
		domains: import.meta.env.ALLOWED_SIGNUP_URLS,
	},
};
