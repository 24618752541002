import { useRouteLoaderData } from "@remix-run/react";
import type { loader as rootLoader } from "../root";
import { invariant } from "~/lib/utils";


/**
 * @returns the request info from the root loader
 */
export function useRequestInfo() {
    const data = useRouteLoaderData<typeof rootLoader>("root");
    invariant(data?.requestInfo, "No requestInfo found in root loader");

    const requestInfo = data.requestInfo;
    const pathSegments = requestInfo.path.split('/');

    /**
     * Adjusts the given URL path based on the hostname prefix.
     * @param path - The URL path to adjust.
     * @param prefix - The prefix to check in the hostname.
     * @returns The adjusted URL path.
     */
    const adjustUrlForHostname = (path: string, prefix: string) => {
        if (!requestInfo?.origin) {
            return path; // No adjustment if origin is not available
        }

        // Extract hostname from origin
        const hostname = new URL(requestInfo.origin).hostname;

        // Adjust path based on hostname prefix
        return hostname.startsWith(prefix) ? path.replace('/insurer', '') : path;
    };

    return {
        ...requestInfo,
        userType: pathSegments[1],
        adjustUrlForHostname,
    };
}